<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-8 pt-lg-9" style="background-color: white">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            points="2560 0 2560 100 0 100"
            style="fill: #8f384d; stroke: #8f384d; stroke-width: 1"
          />
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <!-- Table -->
      <b-row class="justify-content-center">
        <b-col lg="6" md="8">
          <b-card no-body class="bg-secondary border-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>สมัครสมาชิกใหม่</small>
              </div>
              <b-form @submit="onSubmit">
                <!-- <b-form-group label="เพศ">
                  <b-form-radio
                    class="custom-control-inline"
                    v-model="form.sex"
                    name="some-radios"
                    value="ชาย"
                    >ชาย</b-form-radio
                  >
                  <b-form-radio
                    class="custom-control-inline"
                    v-model="form.sex"
                    name="some-radios"
                    value="หญิง"
                    >หญิง</b-form-radio
                  >
                  <b-form-radio
                    class="custom-control-inline"
                    v-model="form.sex"
                    name="some-radios"
                    value="อื่นๆ"
                    >อื่นๆ</b-form-radio
                  >
                </b-form-group> -->

                <div v-for="row in form_auto" :key="row.id">
                  <base-input
                    label="การสอบ"
                    v-if="row.id == 'department'"
                  >
                    <select class="form-control" v-model="row.var">
                      <option
                        v-for="(t, index) in $store.state.department_names.slice(0, 9)"
                        :key="index"
                        :value="index"
                      >
                        {{ t.text }}
                      </option>
                    </select>
                  </base-input>
                  <b-form-group v-if="row.id != 'department'">
                    <b-input-group v-if="row.id != 'university'">
                      <b-input-group-prepend is-text>
                        <i :class="row.icon"></i>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="row.var"
                        :id="`${row.id}`"
                        :ref="row.id"
                        :type="row.kind"
                        :placeholder="row.name"
                        required
                      ></b-form-input>
                    </b-input-group>
                    <b-input-group v-else>
                      <b-input-group-prepend is-text>
                        <i :class="row.icon"></i>
                      </b-input-group-prepend>
                      <vue-bootstrap-typeahead
                        v-model="row.var"
                        :id="`${row.id}`"
                        :ref="row.id"
                        :placeholder="row.name"
                        :data="$store.state.universities"
                        required
                        style="width: 87%"
                      >
                      </vue-bootstrap-typeahead>
                    </b-input-group>
                  </b-form-group>
                </div>
                <b-form-group>
                  <b-form-checkbox v-model="agree">
                    <span class="text-muted"
                      >I agree with the
                      <a href="/privacypolicy" target="_blank"
                        >Privacy Policy</a
                      ></span
                    >
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox v-model="agree2">
                    <span class="text-muted"
                      >I agree with the
                      <a href="/refundpolicy" target="_blank"
                        >Refund Policy</a
                      ></span
                    >
                  </b-form-checkbox>
                </b-form-group>
                <div class="text-center">
                  <b-button variant="primary" class="mt-4" type="submit"
                    >ลงทะเบียน</b-button
                  >
                </div>
              </b-form>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/forgotpassword" class="text-light"
                ><small>ลืมรหัสผ่าน</small></router-link
              >
            </b-col>
            <b-col cols="6" class="text-right">
              <router-link to="/login" class="text-light"
                ><small>เข้าสู่ระบบ</small></router-link
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "register",
  components: {},
  data() {
    return {
      form_auto: [
        {
          var: "",
          id: "firstname",
          name: "ชื่อจริง",
          kind: "text",
          placeholder: "ดีใจ",
          icon: "ni ni-single-02",
        },
        {
          var: "",
          id: "lastname",
          name: "นามสกุล",
          kind: "text",
          placeholder: "ใจดี",
          icon: "ni ni-circle-08",
        },
        // {
        //   var: "",
        //   id: "age",
        //   name: "อายุ",
        //   kind: "number",
        //   placeholder: "25",
        //   icon: "ni ni-user-run",
        // },
        {
          var: "",
          id: "phone",
          name: "เบอร์โทรศัพท์",
          kind: "number",
          placeholder: "0943751375",
          icon: "ni ni-mobile-button",
        },
        // {
        //   var: "",
        //   id: "job",
        //   name: "อาชีพ",
        //   kind: "text",
        //   placeholder: "วิศวกร",
        //   icon: "ni ni-settings",
        // },
        // {
        //   var: "",
        //   id: "job_year",
        //   name: "ทำงานมาแล้วกี่ปี",
        //   kind: "number",
        //   placeholder: "1",
        //   icon: "ni ni-watch-time",
        // },
        // {
        //   var: "",
        //   id: "korvor",
        //   name: "สอบ กว มาแล้วกี่ครั้ง",
        //   kind: "number",
        //   placeholder: "1",
        //   icon: "ni ni-trophy",
        // },
        {
          var: "",
          id: "university",
          name: "จบการศึกษาจาก (มหาวิทยาลัย)",
          kind: "text",
          placeholder: "มหาวิทยาลัยขอนแก่น",
          icon: "ni ni-hat-3",
        },
        {
          var: "",
          id: "department",
          name: "สาขาที่จะสอบ",
          kind: "text",
          placeholder: "สาขาที่จะสอบ",
          icon: "ni ni-hat-3",
        },
        {
          var: "",
          id: "username",
          name: "Email",
          kind: "email",
          placeholder: "gg@gmail.com",
          icon: "ni ni-email-83",
        },
        {
          var: "",
          id: "password",
          name: "รหัสผ่าน",
          kind: "password",
          placeholder: "",
          icon: "ni ni-lock-circle-open",
        },
      ],
      form: {
        sex: "",
        firstname: "",
        lastname: "",
        age: "",
        phone: "",
        job: "",
        job_year: "",
        korvor: "",
        university: "",
        department: "",
        username: "",
        password: "",
      },
      agree: true,
      agree2: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.form.password.length < 6) {
        this.$store.commit("SHOW_ALERT", { msg: "รหัสผ่านต้องมี 6 ตัวขึ้นไป" });
        return false;
      }
      var e = this.$parent.$parent.$parent;
      for (let i = 0; i < this.form_auto.length; i++) {
        const element = this.form_auto[i];
        if (element.id == "department") {
          if (element.var === "") {
            this.$store.commit("SHOW_ALERT", {
              msg: "กรุณาเลือกสาขาที่จะสอบ",
            });
            return false;
          }
        } else {
          if (element.var === "") {
            // console.log(element.id);
            // console.log(this.$refs[element.id]);
            this.$refs[element.id].focus();
            return false;
          }
        }
      }
      if (!this.agree) {
        this.$store.commit("SHOW_ALERT", {
          msg: "กรุณายอมรับเงื่อนไขการสมัคร",
        });
        return false;
      }
      //   e.register(this.form);
      this.form.username = this.form.username.toLowerCase();
      var payload = this.form;
      //   console.log(payload);
      this.$store.commit("REGISTER", payload);
    },
  },
  watch: {
    form_auto: {
      handler: function (val) {
        // console.log(val);
        for (let i = 0; i < val.length; i++) {
          const element = val[i];
          this.form[element.id] = element.var;
        }
        // console.log(this.form);
      },
      deep: true,
    },
  },
};
</script>
<style></style>
